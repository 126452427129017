function getOfficialLevel(level) {
  switch (level) {
    case 'C':
      return 'C (Régional)';
    case 'B':
      return 'B (National)';
    case 'A':
      return 'A (International)';
    default:
      return 'Non qualifié';
  }
}

export default getOfficialLevel;
