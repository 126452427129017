<template>
  <b-overlay :show="showOverlay">
    <h3>
      {{ $DateTime.fromISO(race.raceDate).toISODate()}} -
      {{getRaceName(race.eventName, race.place, race.raceName)}}</h3>
    <hr/>
    <span v-if="$auth.check(['admin','cna'])">
      <a href="#" @click="downloadPce">Télécharger le PCE</a>
    </span>
    <b-button v-b-toggle="'technicalData'" class="m-1">Informations techniques</b-button>
    <b-collapse id="technicalData">
      <b-card>
        <h5>Officiels</h5>
        <ul>
          <li class="official" v-for='official in officials' :key="official.officialId">
            {{official.role}} - {{titleCase(official.personName)}} -
             {{getOfficialLevel(official.officialLevel)}}
            {{official.isOfficialATrainee ? " - stagiaire" : ""}}
            {{official.points > 0 ? ` (${official.points} pts)`: ""}}
          </li>
        </ul>
      </b-card>
    </b-collapse>
    <race-results :results="results" :raceName="race.raceName"></race-results>
  </b-overlay>
</template>

<script>
import { getRaceName } from '../../lib/race';
import titleCase from '../../lib/string';
import getOfficialLevel from '../../lib/official';
import RaceResults from '../../components/RaceResults.vue';

export default {
  name: 'RaceDisplay',
  components: {
    RaceResults,
  },
  data() {
    return {
      showOverlay: false,
      results: [],
      race: {},
      officials: [],
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    titleCase,
    getRaceName,
    getOfficialLevel,
    async getData() {
      this.showOverlay = true;
      try {
        await Promise.all([
          this.getRaceAndResults(),
          this.getOfficials(),
        ]);
        this.showOverlay = false;
      } catch (error) {
        this.showOverlay = false;
      }
    },
    async getRaceAndResults() {
      const response = await this.axios.get(`/ffck/race/${this.$route.params.raceId}`);
      if (response && response.data) {
        this.race = response.data.race;
        this.results = response.data.results;
      }
    },
    async getOfficials() {
      const response = await this.axios.get(`/ffck/officials/actions?raceId=${this.$route.params.raceId}`);
      if (response && response.data) {
        this.officials = response.data;
      }
    },
    async downloadPce() {
      this.showOverlay = true;
      try {
        const response = await this.axios.get(`/race/${this.$route.params.raceId}/export/pce`);
        if (response && response.data) {
          this.forceFileDownload(response, `${this.$route.params.raceId}.pce`);
        }
        this.showOverlay = false;
      } catch (error) {
        this.showOverlay = false;
      }
    },
    forceFileDownload(response, title) {
      // console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style scoped>
  .card-body {
    padding: 0.25rem;
  }
  .official {
    font-size: 10px;
  }
</style>
